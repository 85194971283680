import React from "react";

export default function TopRightShape({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="690"
      viewBox="0 0 1000 690"
      aria-hidden="true"
      className={className}
    >
      <path
        fill="currentColor"
        d="M990.31 1.03V275.4l-165.27-91.46-72.63-40.19V.81h-9.69v142.94l-72.63 40.19-165.25 91.46V1.03l.41-.22h-19.12L252.42 130.15 18.71.81H0v283.36c0 1.79.99 3.43 2.57 4.28l247.58 131.86a4.846 4.846 0 0 0 4.56 0l240.46-128.07v262.93c0 1.81 1 3.46 2.6 4.29l247.58 129.16c.7.37 1.47.56 2.24.56.87 0 1.74-.24 2.51-.71a4.84 4.84 0 0 0 2.33-4.14V423.47L997.5 287.85l.04-.04.07-.02c.51-.3.84-.77 1.2-1.21.16-.19.4-.32.52-.53.42-.73.66-1.56.66-2.43V.81H989.9l.41.22zM247.58 407.97 9.69 281.26V6.89L37.53 22.3l210.05 116.24v269.43zm247.58-126.71-237.89 126.7V138.54L467.31 22.3l27.84-15.41v274.37zm247.58 395.09-237.89-124.1V291.84l165.84 91.76 72.06 39.88v252.87zm4.84-261.26L510 283.62l237.57-131.48 237.57 131.48-237.56 131.47z"
      />
    </svg>
  );
}
