
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import dynamic from "next/dynamic";
import { getResource, getResourceCollection } from "next-drupal";
import useTranslation from "next-translate/useTranslation";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { getGlobals } from "lib/getGlobals";
import { formatDateYMD } from "lib/formatDate";
import Layout from "components/Layout";
import Meta from "components/Meta";
import BottomLeftShape from "components/Shapes/BottomLeftShape";
import TopRightShape from "components/Shapes/TopRightShape";
import { LISTS, getMeta } from "lib/getMeta";

const FeaturesBlock = dynamic(() => import("components/Block/FeaturesBlock"));
const WorkflowBlock = dynamic(() => import("components/Block/WorkflowBlock"));
const BasicBlock = dynamic(() => import("components/Block/BasicBlock"));
const NodeTestimonyPreviewList = dynamic(() =>
  import("components/Testimony/NodeTestimonyPreviewList")
);

export default function IndexPage({ globals, meta, nodes, blocks }) {
  const { t } = useTranslation("home");
  const hasTestimonies = nodes.testimonies?.length > 0;

  return (
    <Layout globals={globals} banners={nodes.banners} home>
      <Meta
        title={meta?.title || t("meta.title")}
        description={meta?.description || t("meta.description")}
      />

      <div className="absolute top-0 right-0" aria-hidden="true">
        <TopRightShape className="w-[14.78vw] h-auto text-primary/30" />
      </div>

      {blocks.features && <FeaturesBlock data={blocks.features} />}

      {blocks.workflow && <WorkflowBlock data={blocks.workflow} />}

      {blocks.insight && hasTestimonies && (
        <section className="relative py-28">
          <div className="absolute bottom-0 left-0" aria-hidden="true">
            <BottomLeftShape className="w-[14.78vw] h-auto text-primary/30" />
          </div>
          <div className="container px-4 mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <BasicBlock data={blocks.insight} showTitle animate />
              <NodeTestimonyPreviewList nodes={nodes.testimonies} />
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

 async function _getStaticProps(context) {
  const localeContext = {
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  };

  const now = formatDateYMD();

  const bannersParams = new DrupalJsonApiParams();

  bannersParams
    .addGroup("forthcoming", "OR")
    .addInclude(["field_image", "field_sm_image"])
    .addFilter("status", 1)
    .addFilter("field_date_end", now, ">=", "forthcoming")
    .addFilter("field_date_start", now, ">=", "forthcoming")
    .addSort("field_weight", "ASC")
    .addPageLimit(5);

  const banners = await getResourceCollection("node--banner", {
    params: bannersParams.getQueryObject(),
    ...localeContext,
  });

  const featuresParams = new DrupalJsonApiParams();

  featuresParams.addInclude([
    "field_video_thumbnail",
    "field_paragraphs",
    "field_paragraphs.field_icon",
  ]);

  const features = await getResource(
    "block_content--features",
    "87bcdbcb-7e1c-4bc3-b1bc-879287afde8f",
    {
      params: featuresParams.getQueryObject(),
      ...localeContext,
    }
  );

  const workflowParams = new DrupalJsonApiParams();

  workflowParams.addInclude([
    "field_paragraphs",
    "field_paragraphs.field_icon",
  ]);

  const workflow = await getResource(
    "block_content--workflow",
    "a3d73bf1-f6c6-46f2-8547-ca8c9386d545",
    {
      params: workflowParams.getQueryObject(),
      ...localeContext,
    }
  );

  const testimoniesParams = new DrupalJsonApiParams();

  testimoniesParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addSort("created", "DESC")
    .addPageLimit(5);

  const testimonies = await getResourceCollection("node--testimony", {
    params: testimoniesParams.getQueryObject(),
    ...localeContext,
  });

  const insight = await getResource(
    "block_content--basic",
    "e2c48615-d6cc-492a-a8f5-50eadd292ac9",
    localeContext
  );

  return {
    props: {
      globals: await getGlobals(context),
      meta: await getMeta(LISTS.HOME, context),
      nodes: {
        banners,
        testimonies,
      },
      blocks: {
        features,
        workflow,
        insight,
      },
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  